 .page.terms-and-conditions {
    padding: 50px 40px;
    margin: 50px;
    background: white;
    /* box-shadow: 2px 2px 2px rgba(0,0,0,0.6); */
    max-width: 800px;
    min-width: 500px;
    margin-bottom: 0 !important;
  }
  
  #terms-and-conditions {
    font-size: 14px; 
  }


  
  #terms-and-conditions  h1 {
    font-size: 34px;
  }
  
  #terms-and-conditions ol {
    counter-reset: item;
  }

  #terms-and-conditions li {
    display: block;
    margin: 20px 0;
    position: relative;
  }
  
  /* #terms-and-conditions li:before {
    position: absolute;
    top: 0;
    margin-left: -50px;
    color: magenta;
    content: counters(item, ".") "    ";
    counter-increment: item;
  } */

  .downPayment {
    /* display: flex; */
    gap: 10px;
    /* align-items: center; */
}