.first_section_container {
  width: 100%;
  height: calc(100vh - 20px);
  background-image: url("../../../assets/images/home_image.png");
  background-size: cover;
  background-repeat: repeat;
  background-position: center center;
  margin-top: 50px;
  position: relative;
}

.first_section_container .fs_content {
  display: flex;
  align-items: start;
  position: absolute;
  width: 100%;
  z-index: 2;
  /* height: 100%; */
}
.first_section_container .fs_content > div {
  flex: 1;
}

.first_section_container .fs_content .left_side {
  margin-top: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #fff;
}

.first_section_container .fs_content .left_side h4 {
  font-size: 50px;
}

.first_section_container .fs_content .left_side h5 {
  font-size: 30px;
}
.first_section_container .fs_content .left_side h3 {
  font-size: 70px;
  font-weight: bolder;
  letter-spacing: 5px;
}

.first_section_container .fs_content .left_side h6 {
  font-size: 20px;
}

.first_section_container .fs_content .right_side {
  background-color: #0000005d;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #fff;
  padding: 30px;
}

.fs_content .right_side .info .title {
  font-size: 25px;
  font-weight: bolder;
}
.fs_content .right_side .info .title.be_sure {
  font-size: 14px;
  font-weight: normal;
}
.home_container {
  overflow: hidden;
}
.fs_content .right_side .info .username {
  font-size: 40px !important;
  font-weight: bolder;
}
.page.terms-and-conditions {
  padding-right: 0 !important;
}

.fs_content .right_side .info .info_data {
  font-size: 25px;
  color: #efefef;
}

.elementor-counter .elementor-counter-title {
  text-align: center !important;
}
@media (max-width: 772px) {
  .first_section_container .fs_content {
    flex-direction: column !important;
  }
  .first_section_container .fs_content .left_side {
    text-align: center !important;
  }
  .first_section_container {
    min-height: 120vh !important;
  }
  .elementor-counter .elementor-counter-title {
    font-size: 14px !important;
  }
  .elementor-counter {
    margin: 10px;
  }

  .author-details h4 {
    font-size: 11px !important;
  }

  .author-details p {
    font-size: 9px !important;
    color: #666;
  }

  .page.terms-and-conditions {
    padding: 30px 20px !important;
    margin: 20px 10px !important;
  }
  .page.terms-and-conditions {
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .footer {
    flex-direction: column !important;
    height: 150px !important;
  }
  .footer .text-sm-end.d-none.d-sm-block {
    margin: 20px !important ;
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .footer .row > *,
  .footer .text-sm-end.d-none.d-sm-block {
    justify-content: center !important;
    text-align: center;
    justify-content: center !important;
  }
  .first_section_container .fs_content .left_side {
    text-align: center !important;
    margin: 60px auto;
    font-size: 14px !important;
  }

  .banner-left h1,
  .fs_content .right_side .info .title {
    font-size: 18px !important;
  }
  .service-title h3 {
    text-align: start !important;
    font-size: 12px !important;
  }
  .first_section_container .fs_content .left_side > * {
    font-size: 20px !important;
  }

  .description-image-terms > * {
    padding: 20px 0 !important;
  }

  .first_section_container .fs_content .right_side {
    padding: 10px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  .signature-component {
    padding: 20px 30px !important;
  }

  .signature-component {
    display: flex;
    /* flex-direction: column; */
    /* align-items: flex-start; */
    /* background-color: black; */
    padding: 20px 90px;
    color: black;
  }
  .fs_content .right_side .info .info_data {
    font-size: 15px !important;
  }
  .first_section_container .fs_content .left_side h3 {
    text-align: center;
    /* font-size: 70px; */
  }
 

  


}

.signature-pad {
  margin-top: 0 !important;
  /* border-right: 1px solid; */
  border-left: 1px solid;
  /* padding: 10px; */
}
.signature-component {
  border: 1px solid;
  margin: auto !important;
}
.signature-component {
  padding: 10px;
  width: fit-content !important;
}
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
.signature-component button {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.rowDiv button {
  padding: 8px 20px !important;
}

.signature-component > * {
  padding: 10px;
}

.signature-component {
  justify-content: space-between;
  padding-left: 20px !important;
  padding-right: 20px !important;
}