.testimonials-section {
    padding: 80px 0 10px;
  }
  
  .section-title h2 {
    font-size: 28px; /* Smaller font size */
    font-weight: 700;
    color: #333;
    margin-bottom: 30px;
  }
  
  .mySwiper {
    padding-top: 50px;
    padding: 50px !important;
  }
  
  .testimonial-item {
    background-color: #fff;
    padding: 20px; /* Smaller padding */
    min-height: 280px !important;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
  }
  
  .testimonial-content {
    font-size: 12.5px; /* Smaller font size */
    color: #666;
    min-height: 100px;
    line-height: 1.4;
    margin-bottom: 20px;
  }
  
  .testimonial-author {
    display: flex;
    align-items: center;
  }
  
  .testimonial-author img {
    width: 50px; /* Smaller image size */
    height: 50px; /* Smaller image size */
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .author-details h4 {
    font-size: 18px; /* Smaller font size */
    font-weight: 600;
    color: #333;
    margin-bottom: 5px;
  }
  
  .author-details p {
    font-size: 14px; /* Smaller font size */
    color: #666;
  }
  
  /* Responsive adjustments */
  @media (min-width: 768px) {
    .testimonial-item {
      padding: 30px;
    }
  }
  
  @media (min-width: 1024px) {
    .testimonial-item {
      padding: 40px;
    }
  }
  
  /* Quotation marks */
  .testimonial-item::before {
    content: '“';
    font-size: 50px;
    color: #ccc;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  
  .testimonial-item::after {
    content: '”';
    font-size: 50px;
    color: #ccc;
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  
  /* Stars */
  .stars {
    color: #f39c12;
    font-size: 16px; /* Smaller font size */
  }
  
